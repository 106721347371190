<template>
  <el-table
    ref="multipleTable"
    border
    :data="tableData"
    tooltip-effect="dark"
    style="width: 100%"
    @selection-change="handleSelectionChange"
  >
    <el-table-column type="selection" align="center"> </el-table-column>
    <el-table-column prop="id" sortable label="ID" align="center">
    </el-table-column>
    <el-table-column
      prop="create_time"
      sortable
      label="创建日期"
      align="center"
    >
    </el-table-column>
    <el-table-column prop="activity_title" label="名称" align="center">
    </el-table-column>
    <el-table-column prop="start_price" sortable label="活动价" align="center">
    </el-table-column>
    <el-table-column
      prop="activity_inventory"
      sortable
      label="库存"
      align="center"
    >
    </el-table-column>
    <el-table-column label="类型" align="center">砍价 </el-table-column>
    <el-table-column label="状态" align="center">
      <template slot-scope="scope">
        <span class="tableStatus">{{ scope.row.status }}</span>
      </template>
    </el-table-column>
    <el-table-column label="操作" align="center">
      <template slot-scope="scope">
        <i class="el-icon-edit poiner" @click="handleEdit(scope.row)"></i>
        <span style="margin: 0 10px"></span>
        <i
          class="el-icon-delete poiner"
          @click="handleDelete(scope.row.id)"
        ></i>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  data() {
    return {
      multipleSelection: [],
    };
  },
  props: ["tableData"],
  methods: {
    // 多选数据 -- 表格
    handleSelectionChange(val) {
      this.$emit("handleSelectionChange", val);
    },
    handleDelete(val) {
      this.$emit("handleDelete", val);
    },
    handleEdit(row) {
      this.$emit("handleEdit", row);
    },
  },
};
</script>

<style></style>
