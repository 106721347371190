<template>
  <div>
    <bread-crumb
      :crumbData="['营销活动', page + '活动']"
      :chooseCrumbData="2"
      @close="onClose"
    ></bread-crumb>

    <div class="searchClass">
      <el-row>
        <el-col :span="6" v-if="page !== '秒杀'">
          <span>日期范围：</span>
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @clear="dateRange = null"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="2" v-if="page !== '秒杀'">
          <el-select v-model="statusValue" clearable placeholder="状态">
            <el-option v-for="item in statusOptions" :key="item" :value="item">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4" v-if="page === '秒杀'"
          ><el-input
            type="text"
            v-model="inpVal"
            @focus="inpfocus"
            placeholder="点击选择商品"
        /></el-col>
        <el-col :span="4">
          <el-input
            v-model="inquireValue"
            placeholder="请输入查询的活动名称"
            clearable
          ></el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="success" size="mini" @click="searchPageInfo">
            <div style="display: flex; align-items: center; font-size: 14px">
              <span class="spriteChart_inquire"></span>
              <span style="margin-left: 5px">查询</span>
            </div>
          </el-button>
        </el-col>
      </el-row>
    </div>

    <div class="handleBtnClass">
      <div style="margin-right: 5px">
        <el-button type="primary" size="mini" @click="onChooseModule">
          <div style="display: flex; align-items: center; font-size: 14px">
            <span class="spriteChart_add"></span>
            <span style="margin-left: 5px">{{
              page == "秒杀" ? "新增" : "发布活动"
            }}</span>
          </div>
        </el-button>
      </div>
      <div style="margin-right: 5px">
        <el-button type="danger" size="mini" @click="deleteList">
          <div style="display: flex; align-items: center; font-size: 14px">
            <span class="spriteChart_delete"></span>
            <span style="margin-left: 5px">批量删除</span>
          </div>
        </el-button>
      </div>
    </div>

    <div class="table">
      <PintuanTab
        :table-data="tableData"
        @handleSelectionChange="handleSelectionChange"
        @handleDelete="handleDelete"
        @handleEdit="handleEdit"
        v-if="page == '拼团'"
      />
      <MiaoshaTab
        :table-data="tableData"
        @handleSelectionChange="handleSelectionChange"
        @handleDelete="handleDelete"
        @handleEdit="handleEdit"
        v-else-if="page == '秒杀'"
      />
      <KanjiaTab
        :table-data="tableData"
        @handleSelectionChange="handleSelectionChange"
        @handleDelete="handleDelete"
        @handleEdit="handleEdit"
        v-else-if="page == '砍价'"
      />
      <div class="tableBottom">
        <span
          >显示第&nbsp;{{
            (currentPage - 1) * currentLength + 1
          }}&nbsp;至&nbsp;{{
            (currentPage - 1) * currentLength + tableData.length
          }}&nbsp;项结果，共&nbsp;{{ count }}&nbsp;项</span
        >
        <div
          style="display: flex; justify-content: flex-end; align-items: center"
        >
          <el-pagination
            background
            layout="prev, pager, next"
            :total="count"
            :current-page="currentPage"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <AddProduct
      :productShow="productShow"
      @addConfirm="addConfirm"
      @handleClose="handleClose"
    />
  </div>
</template>
<script>
import PintuanTab from "./tabel/pintuanTab.vue";
import MiaoshaTab from "./tabel/miaoshaTab.vue";
import KanjiaTab from "./tabel/kanjiaTab.vue";
import AddProduct from "../../../addProduct/addProduct.vue";
export default {
  components: { PintuanTab, MiaoshaTab, KanjiaTab, AddProduct },
  data() {
    return {
      // 搜索操作
      dateRange: "",
      statusValue: "",
      inquireValue: "",

      statusOptions: ["待发布", "进行中", "已关闭"],

      // 表格
      currentPage: 1,
      currentLength: 10,
      multipleSelection: [],
      page: "",
      baseUrl: "",
      child: "",
      deleteUrl: "",
      searchUrl: "",
      inpVal: "",
      productShow: false,
      productList: [],
      commercialId: "",
    };
  },
  created() {},
  mounted() {
    this.page = this.$store.state.chooseModule.page;
    if (this.page == "拼团") {
      this.baseUrl = "/api/group_activity/lst";
      this.child = "pinTuanAddModule";
      this.deleteUrl = "/group_activity/del";
      this.searchUrl = "/api/group_activity/search";
    } else if (this.page == "砍价") {
      this.baseUrl = "api/bargaining_activity/lst";
      this.child = "kanJiaAddModule";
      this.deleteUrl = "/bargaining_activity/del";
      this.searchUrl = "/api/bargaining_activity/search";
    } else if (this.page == "秒杀") {
      this.baseUrl = "/api/seckill_activity/lst";
      this.child = "miaoShaAddModule";
      this.deleteUrl = "/seckill_activity/del";
      this.searchUrl = "/api/seckill_activity/search";
    }
    this.getPageInfo();
  },
  computed: {
    tableData() {
      return this.$store.state.commonModule.paging.data;
    },
    count() {
      return this.$store.state.commonModule.paging.count;
    },
  },
  destroyed() {
    console.log("destroyed");
  },
  methods: {
    // 获取全部数据
    getPageInfo() {
      const payload = {};
      payload.baseURL = this.baseUrl;
      payload.currentPage = this.currentPage;
      payload.currentPageTotal = this.currentLength;
      payload.search = {};
      payload.search.firstTime = this.dateRange[0];
      payload.search.lastTime = this.dateRange[1];
      payload.search.status = this.statusValue;
      payload.search.search = this.inquireValue;
      this.$store.dispatch("commonModule/getPagingInfo", payload);
    },
    inpfocus() {
      this.productShow = true;
    },
    addConfirm(list) {
      this.productList = [list[list.length - 1]];
      this.productShow = false;
      this.inpVal = this.productList[0].product_name;
    },
    handleClose() {
      this.productShow = false;
    },
    // 关闭子页面
    onClose() {
      this.$store.commit("chooseModule/onChoose", "openMainModule");
    },
    //搜索
    searchPageInfo() {
      const payload = {};
      payload.baseURL = this.searchUrl;
      payload.search = this.inquireValue;
      payload.currentPage = this.currentPage;
      payload.currentLength = this.currentLength;
      if (this.page !== "秒杀") {
        payload.firstTime = this.dateRange[0];
        payload.lastTime = this.dateRange[1];
        payload.status = this.statusValue;
      } else {
        payload.product = this.productList;
        this.$store.dispatch("commonModule/miaoshaSerach", payload);
        return;
      }

      this.$store.dispatch("commonModule/searchPageInfo", payload);
    },
    // 选择页面
    onChooseModule() {
      this.$store.commit("chooseModule/onChoose", { chooseModule: this.child });
    },
    // 分页 -- 当前页改变传值
    currentChange(val) {
      this.currentPage = val;
      this.getPageInfo();
    },

    // 查询
    onInquireInfo() {
      this.currentPage = 1;
      this.getPageInfo();
    },
    //修改
    handleEdit(row) {
      this.$store.commit("chooseModule/onChoose", {
        chooseModule: this.child,
        row,
      });
    },
    // 删除 -- 单条
    handleDelete(id) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post(
              this.deleteUrl,
              this.$qs.stringify({
                id,
              })
            )
            .then(() => {
              this.getPageInfo();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 多选数据 -- 表格
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 删除 -- 批量
    deleteList() {
      if (this.multipleSelection != "") {
        this.$confirm("是否删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            const idArr = this.multipleSelection.map((item) => {
              return item.id;
            });
            this.$http
              .post(
                this.deleteUrl,
                this.$qs.stringify({
                  id: idArr,
                })
              )
              .then(() => {
                this.getPageInfo();
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.$message.error("请选择需删除的数据！");
      }
    },
  },
};
</script>
<style scoped lang="less">
.icon {
  cursor: pointer;
}

.icon:hover {
  color: red;
}

.tableStatus {
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  padding: 3px;
  font-weight: bold;
}
</style>
